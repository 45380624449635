import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import {
  IGNORE_METADATA_FILTERS_FOR_GROUPS,
  useGroupByState,
  useGroupByUpdater
} from "./group-by.context";
import useGeoRadius from "./hooks/useGeoRadius";

function MetadataFilterToolbar() {
  const { selectedCategory, selectedMetadataFilters } = useGroupByState();
  const plays = useSelector((state: RootState) => state.filter.currentPlayList);
  const { data: radiuses } = useGeoRadius();
  const [dimensions, setDimensions] = useState([]);

  useEffect(() => {
    if (radiuses) {
      const selectedRadius = selectedMetadataFilters["radius"];
      const newDimensions = radiuses.dimensions.find((x) => x.name === selectedRadius);
      setDimensions([
        {
          leftWidth: newDimensions.leftWidth,
          rightWidth: newDimensions.rightWidth,
          upHeight: newDimensions.upHeight,
          downHeight: newDimensions.downHeight
        }
      ]);
    } else {
      setDimensions([]);
    }
  }, [radiuses, selectedMetadataFilters]);
  const updateGroupByState = useGroupByUpdater();

  const changeSelectedMetadataFilter = (key: string, value: string) => {
    return updateGroupByState({
      type: "selected_metadata_filter",
      payload: {
        key,
        value
      }
    });
  };

  const columns: ColumnsType = [
    {
      title: "Left Width",
      dataIndex: "leftWidth",
      key: "leftWidth"
    },
    {
      title: "Right Width",
      dataIndex: "rightWidth",
      key: "rightWidth"
    },
    {
      title: "Up Height",
      dataIndex: "upHeight",
      key: "upHeight"
    },
    {
      title: "Down Height",
      dataIndex: "downHeight",
      key: "downHeight"
    }
  ];

  if (
    selectedCategory?.metadataFilters?.length === 0 ||
    IGNORE_METADATA_FILTERS_FOR_GROUPS.includes(selectedCategory?.name)
  ) {
    return null;
  }

  return (
    <Wrapper>
      {selectedCategory?.metadataFilters?.map((filter) => {
        const selectedValue = selectedMetadataFilters[filter.key] || undefined;
        // if the selectedValue is not in the filter options, we set it to undefined
        if (selectedValue && !filter.options.includes(selectedValue)) {
          changeSelectedMetadataFilter(filter.key, undefined);
        }
        return (
          <>
            <SelectWrapper key={filter.key}>
              <label>{filter.name}</label>
              <Select
                data-testid={`metadata-select-${filter.key}`}
                showSearch={true}
                allowClear={true}
                value={selectedValue}
                onChange={(value) => changeSelectedMetadataFilter(filter.key, value)}
                options={filter.options.map((option) => ({
                  label: option,
                  value: option
                }))}
                popupClassName="modal-select"
                placeholder="All"
              />
            </SelectWrapper>
          </>
        );
      })}
      {selectedCategory?.metadataFilters?.find((x) => x.key == "radius") &&
        plays?.length === 1 &&
        dimensions.length > 0 && (
          <WrapperRow>
            <Wrapper>
              <Table
                data-testid="geo-calc-table"
                columns={columns}
                dataSource={dimensions}
                rowKey="jobId"
                pagination={false}
                size="small"
              />
            </Wrapper>
          </WrapperRow>
        )}
    </Wrapper>
  );
}

export default MetadataFilterToolbar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 var(--space-4);
`;
const SelectWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  .ant-select {
    width: 100%;
  }
`;

const WrapperRow = styled.div`
  display: flex;
  padding: 0 var(--space-2);
  gap: 10px;
  flex-direction: column;
`;
