// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Warning } from "@material-ui/icons";
import ListIcon from "@material-ui/icons/List";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { mdiFactory } from "@mdi/js";
import type { MenuProps } from "antd";
import { Checkbox, Input, Typography } from "antd";
import axios from "axios";
import classnames from "classnames";
import { Subject } from "rxjs";
import {
  FilterState,
  addToLockedColor,
  removeLockedColor,
  setUpdatePlayLegend,
  toggleShowGroupsNotInFilter,
  updateShowGroupsNotInFilter
} from "store/features";
import {
  addManyPropertiesToFilter as addManyFacilityPropertiesToFilter,
  removeAllProperties as removeAllFacilityProperties,
  setFilterState as setFacilityFilterState,
  setFiltersLoaded as setFacilityFiltersLoaded,
  setResetPolygonFilter as setFacilityResetPolygonFilter,
  setSavedFilters as setSavedFacilityFilters,
  setSelectedSavedFilter as setSelectedFacilitySavedFilter,
  toggleShowGroupsNotInFilter as toggleFacilityShowGroupsNotInFilter,
  updateActiveFilterCount as updateFacilityActiveFilterCount,
  updateShowGroupsNotInFilter as updateFacilityShowGroupsNotInFilter
} from "store/features/filter/facilityFilterSlice";
import {
  addManyPropertiesToFilter,
  removeAllProperties,
  setFilterState,
  setFiltersLoaded,
  setResetPolygonFilter,
  setSavedFilters,
  setSelectedSavedFilter,
  updateActiveFilterCount
} from "store/features/filter/filterSlice";
import {
  setGlobalGroupBy,
  setHasCheckedBins,
  setHasCheckedBinsFacility,
  setSelectedFacilityGroups,
  setSelectedGroups
} from "store/features/groupBy/groupBySlice";
import { setViewLock } from "store/features/map/mapSlice";
import { getNormalizationItems } from "store/features/normalizeBy/normalizeBySlice";
import { updateSelectedProjectTransaction } from "store/features/project/projectSlice";
import {
  setActiveColorPalette,
  setActiveFacilityColorPalette
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import { useColumnsDictionary, useCumPlusForecast, useProperty } from "hooks";

import {
  ActiveFilterItem,
  BinType,
  FilterItemT,
  IGroupBy,
  LegendItemModel,
  QuantileState,
  SavedFilterModel
} from "models";
import { EntityKind } from "models/entityKind";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { ErrorBoundary, Tooltip } from "components/base";
import FocusTreeMap from "components/groupBy/FocusTreeMap";
import { GroupBy as GroupByIcon } from "components/icons";
import ChartTreeIcon from "components/icons/ChartTree";
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProject } from "components/project/projects/hooks";
import { Legend } from "components/ui";
import useRefreshPreferredColorsInStore from "components/user-settings/hooks/refreshPreferredColorsInStore";
import { useUserDefaults } from "components/user/hooks";

import ActiveFilter from "./ActiveFilter";
import BinSettings from "./BinSettings";
import "./GroupByFilterActivity.scss";
import SaveActiveFilter from "./SaveActiveFilter";
import SavedFilterSelection from "./SavedFilterSelection";
import ToggleButton from "./ToggleButton";
import UndoFilter from "./UndoFilter";
import { Collapse, Panel } from "./collapse";
import {
  BatchOperationsToggle,
  ColorPaletteListToggle,
  PresetDropdown,
  PresetFields,
  SortOptionsToggle
} from "./focus";
import { PresetDropdownContext } from "./focus/PresetDropdown";
import { ProductionButtonGroup } from "./production";
import { StyledCheckBoxEmptyIcon, StyledCheckBoxIcon, StyledExcludeIcon } from "./shared";
import toLegendItem from "./toLegendItem";
import { getLegendFilter, updateGroupBy } from "./util";

const dataRoot = process.env.REACT_APP_DATA_ROOT;
const { Text } = Typography;
const NUMBER_OF_WELL_SYSTEM_FILTERS = 2;

let outerSelectedLegendItems: LegendItemModel[] = [];

export interface GroupByFilterActivityProps {
  entityKind: EntityKind;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GroupByFilterActivity: FunctionComponent<GroupByFilterActivityProps> = ({
  entityKind
}) => {
  // context and hooks
  const dispatch = useDispatch();
  const refreshPreferredColorsInStore = useRefreshPreferredColorsInStore();
  const defaults = useUserDefaults();

  // state from store
  const categoryList = useSelector((state: RootState) => state.groupBy.categoryList);

  const getSelectorField = (state: RootState, field: keyof FilterState) => {
    const reducer = entityKind === EntityKind.Well ? state.filter : state.facilityFilter;
    return reducer[field];
  };

  const facilityList = useSelector((state: RootState) => state.groupBy.facilityFields);
  const filterId = useSelector((state: RootState) => getSelectorField(state, "filterId"));
  const groupBy = useSelector((state: RootState) => {
    if (entityKind === EntityKind.Facility) {
      return state.groupBy.globalFacilityFocus;
    }
    return state.groupBy.globalGroupBy;
  });
  const isFiltering = useSelector((state: RootState) =>
    getSelectorField(state, "isFiltering")
  );
  const normalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const polygonFilter = useSelector((state: RootState) =>
    getSelectorField(state, "polygonFilter")
  );

  const currentFilter = useSelector((state: RootState) =>
    getSelectorField(state, "currentFilter")
  );
  const setSavedEntityFilters = (filters: FilterItemT[]) => {
    if (entityKind === EntityKind.Well) {
      dispatch(setSavedFilters(filters));
    } else {
      dispatch(setSavedFacilityFilters(filters));
    }
  };

  const setEntityFiltersLoaded = (loaded: boolean) => {
    if (entityKind === EntityKind.Well) {
      dispatch(setFiltersLoaded(loaded));
    } else {
      dispatch(setFacilityFiltersLoaded(loaded));
    }
  };

  const dispatchToggleShowGroupsNotInFilter = () => {
    if (entityKind === EntityKind.Well) {
      dispatch(toggleShowGroupsNotInFilter());
    } else {
      dispatch(toggleFacilityShowGroupsNotInFilter());
    }
  };

  const dispatchUpdateShowGroupsNotInFilter = (value: boolean) => {
    if (entityKind === EntityKind.Well) {
      dispatch(updateShowGroupsNotInFilter(value));
    } else {
      dispatch(updateFacilityShowGroupsNotInFilter(value));
    }
  };

  const propertiesFilter = useSelector((state: RootState) =>
    getSelectorField(state, "propertiesFilter")
  );
  const selectedFilter = useSelector((state: RootState) =>
    getSelectorField(state, "selectedSavedFilter")
  );
  const selectedProjectTransaction = useSelector(
    (state: RootState) => state.project.selectedProjectTransaction
  );
  const txnId = useSelector((state: RootState) => {
    return state.map.txnId;
  });
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const updatePlayLegend = useSelector(
    (state: RootState) => state.filter.updatePlayLegend
  );
  const wellListFilter = useSelector((state: RootState) => state.filter.wellListFilter);

  const mapViewLocked = useSelector((state: RootState) => state.map.viewLock);
  const lockedColorsInStore = useSelector((state: RootState) => state.app.lockedColors);
  const showGroupsNotInFilter = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.showGroupsNotInFilter
      : state.facilityFilter.showGroupsNotInFilter
  );
  const savedFilters = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.savedFilters
      : state.facilityFilter.savedFilters
  );

  // state
  const [isActiveFilterVisible, setIsActiveFilterVisible] = useState(true);
  const [isLegendVisible, setIsLegendVisible] = useState(true);

  function getInitialBinState() {
    if (
      groupBy.dataType.toLowerCase() !== "number" ||
      groupBy.dataType.toLowerCase() !== "integer"
    ) {
      return { ...groupBy.bin };
    }
    let initialState = {
      ...groupBy.bin,
      BinType: defaults?.binSettings?.type ?? ("BinSize" as BinType)
    };
    if (defaults?.binSettings?.quantile) {
      initialState = { ...initialState, Quantile: defaults.binSettings.quantile };
    }
    return initialState;
  }

  const [bin, setBin] = useState(getInitialBinState());
  const [defaultFiltersLoaded, setDefaultFiltersLoaded] = useState(false);
  const [readyForDefaultFilter, setReadyForDefaultFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState<LegendItemModel[]>([]);
  const [focusSearch, setFocusSearch] = useState("");
  const [legendItems, setLegendItems] = useState([]);
  const [legendTitle, setLegendTitle] = useState("");
  const [selectedLegendItems, setSelectedLegendItems] = useState(
    outerSelectedLegendItems
  );
  const [showGroupBy, setShowGroupBy] = useState(false);
  const [showTreeMap, setShowTreeMap] = useState(false);
  const [isForecastToggleOn, setIsForecastToggleOn] = useState(false);
  const [selectedForecastFolder, setSelectedForecastFolder] = useState("");

  // custom hooks
  const columnsDictionary = useColumnsDictionary(entityKind);
  const [, setCumPlusForecast] = useCumPlusForecast();
  const { permissions: projectPermissions, selectedProjectId } = useProjectContext();
  const { selectedProject } = useSelectedProject();

  // refs
  const columnsDictionaryRef = useRef(columnsDictionary);
  const categoryListRef = useRef(
    entityKind === EntityKind.Well ? categoryList : facilityList
  );
  const checkedLegendItems = useRef<LegendItemModel[]>([]);
  const currentProjectIdRef = useRef(selectedProjectId);
  const globalNormalizeByRef = useRef(normalizeBy);
  const [warningVisible, setWarningVisible] = useState(false);
  const filterIdRef = useRef(filterId);
  const localBinsRef = useRef(
    sessionStorage.getItem("localBin")
      ? JSON.parse(sessionStorage.getItem("localBin"))
      : {}
  );
  const prevTxnId = useRef({ id: "" });
  const useNormalizeByRef = useRef(useNormalizeBy);
  const groupByTitle = useProperty(
    groupBy.property,
    isForecastToggleOn,
    selectedForecastFolder,
    entityKind
  );

  // derived state
  const canEditBin = groupBy?.dataType.toLowerCase() !== "text";
  const isAllChecked = checkedLegendItems.current?.length === legendItems?.length;
  const dispatchActiveFilterCount = (filterId) => {
    if (entityKind === EntityKind.Facility) {
      dispatch(updateFacilityActiveFilterCount(filterId));
      return;
    }
    dispatch(updateActiveFilterCount(filterId));
  };
  // refs sync
  // TODO: get rid of all ref sync
  useEffect(() => {
    //update normalize by
    useNormalizeByRef.current = useNormalizeBy;
    globalNormalizeByRef.current = normalizeBy;
  }, [useNormalizeBy, normalizeBy]);

  useEffect(() => {
    if (checkedLegendItems.current?.length > 0) {
      entityKind === EntityKind.Well
        ? dispatch(setHasCheckedBins(true))
        : dispatch(setHasCheckedBinsFacility(true));
    } else {
      entityKind === EntityKind.Well
        ? dispatch(setHasCheckedBins(false))
        : dispatch(setHasCheckedBinsFacility(false));
    }
  }, [checkedLegendItems.current, dispatch]);

  useEffect(() => {
    filterIdRef.current = filterId;
    //when filterId changes update the counts
    dispatchActiveFilterCount(filterId);

    const currentGroupBy: IGroupBy = {
      ...groupBy,
      bin: {
        BinSize: "",
        GreaterThan: "",
        LessThan: "",
        MinSize: "",
        BinType: "BinSize",
        UseDynamicBins: groupBy.bin?.UseDynamicBins ?? false
      }
    };

    if (
      groupBy.bin?.BinType === "BinSize" &&
      !groupBy.bin?.IsLocked &&
      groupBy.bin?.UseDynamicBins
    ) {
      // dynamically update bins when filterId changes or bin lock is removed
      updateField(currentGroupBy, true);
    }
  }, [dispatch, filterId, groupBy.bin?.IsLocked]);

  // ref function
  function search(searchTerm, items): LegendItemModel[] {
    if (searchTerm.length === 0) {
      return items;
    }
    const lowerSearchTerm = searchTerm.toLowerCase();
    return items.filter((item) => item.value.toLowerCase().indexOf(lowerSearchTerm) >= 0);
  }

  useEffect(() => {
    setWarningVisible(false);
    const activeFilter = currentFilter?.query?.children_nodes;
    if (activeFilter) {
      activeFilter.forEach((node, i) => {
        if (
          node.predicates.some(
            (x) => x.sourceKey.includes("Less Than") && activeFilter.length - 1 !== i
          )
        ) {
          if (node.predicates[0].property === groupBy.property) {
            setWarningVisible(true);
          }
        }
      });
    }
  }, [currentFilter, groupBy]);

  useEffect(() => {
    //ensure that we only update when the txn id changes
    if (!txnId || (prevTxnId.current.id === txnId.id && entityKind === EntityKind.Well)) {
      return;
    }
    prevTxnId.current = txnId;
    const legend = entityKind === EntityKind.Well ? txnId.legend : txnId.facilityLegend;
    if (!legend) return;
    // TODO: REMOVE PROPERTY NAME SPLITTING
    const propertySplit = groupBy.property.split(".");
    let legendTitle = groupBy.title;
    if (groupByTitle) {
      legendTitle = groupByTitle.fullContextTitleWithNormalization;
    }
    if (propertySplit.length === 3) {
      if (propertySplit[0] === "QC_Meta") {
        legendTitle = `${groupBy.subgroup}` + legendTitle;
      }
    }

    const normalizeBySettings = Object.assign({}, globalNormalizeByRef.current, {
      useNormalizeBy: useNormalizeByRef.current
    });
    setLegendTitle(legendTitle);
    const legendItems = toLegendItem(
      legend.legendItems,
      groupBy,
      propertiesFilter,
      groupByTitle,
      normalizeBySettings
    );
    setLegendItems(legendItems);
    const filtered = search(focusSearch, legendItems);
    setFilteredItemsAndUseLockedColors(filtered);
    checkedLegendItems.current = legendItems.filter((item) => item.isChecked);
  }, [
    groupBy,
    txnId,
    polygonFilter,
    propertiesFilter,
    useNormalizeBy,
    normalizeBy,
    groupByTitle,
    dispatch
  ]);

  useEffect(() => {
    if (!updatePlayLegend.length) return;
    if (entityKind === EntityKind.Well) {
      const legend = new LegendItemModel(
        `(0) ${updatePlayLegend}`,
        "Header.ResourcePlay",
        false,
        1,
        { BinSize: "", LessThan: "", GreaterThan: "", MinSize: "" },
        true,
        0
      );
      legend.color = "";
      legend.fontColor = "";
      legend.isChecked = true;
      legend.isNotEqual = false;
      legend.value = updatePlayLegend;
      legend.text = `(0) ${updatePlayLegend}`;
      replacePlayChecked(legend);
    }
  }, [updatePlayLegend]);

  useEffect(() => {
    setBin(groupBy.bin);
  }, [groupBy, setBin]);

  useEffect(() => {
    const filtered = search(focusSearch, legendItems);
    setFilteredItemsAndUseLockedColors(filtered);
  }, [focusSearch, legendItems, lockedColorsInStore]);

  function updateBin(val) {
    const newBin = Object.assign({}, bin, val);
    const property = groupBy.property;
    const newLocals = {};
    Object.keys(localBinsRef.current).forEach((key) => {
      newLocals[key] = { ...localBinsRef.current[key] };
    });
    newLocals[property] = { ...newBin };
    const localBin = { ...localBinsRef.current, ...newLocals };
    localBinsRef.current = localBin;
    sessionStorage.setItem("localBin", JSON.stringify(localBin));
    setBin(newBin);
  }

  useEffect(() => {
    categoryListRef.current =
      entityKind === EntityKind.Well ? categoryList : facilityList;
    return () => {
      categoryListRef.current = [];
    };
  }, [categoryList, facilityList, entityKind]);

  useEffect(() => {
    columnsDictionaryRef.current = columnsDictionary;
    return () => {
      columnsDictionaryRef.current = {};
    };
  }, [columnsDictionary]);

  function clearAllPropertiesFilter() {
    if (entityKind === EntityKind.Well) {
      dispatch(removeAllProperties());
    } else {
      dispatch(removeAllFacilityProperties());
    }

    dispatchResetPolygonFilter(true);
  }

  function toPdenEnum(source: string): number {
    if (typeof source === "string") {
      switch (source.toLowerCase()) {
        case "public":
          return PdenSourceEnum.Public as number;
        case "private":
          return PdenSourceEnum.Private as number;
        case "hybrid":
          return PdenSourceEnum.Hybrid as number;
        default:
          return parseInt(source);
      }
    }
    return parseInt(source);
  }

  function setFilteredItemsAndUseLockedColors(items: LegendItemModel[]) {
    const itemsWithLockedColors = items.map((item) => {
      const key = `${item.property}:${item.value}`;
      const found = lockedColorsInStore[key];
      if (!found) {
        return item;
      }
      return Object.assign({}, item, {
        color: found.color,
        isColorLocked: true
      });
    });
    setFilteredItems(itemsWithLockedColors);
  }

  const updateField = useCallback(
    (fieldGb: IGroupBy, updateBinsOnly: boolean = false) => {
      function toggleGroupByList() {
        if (!updateBinsOnly) {
          setShowGroupBy(!showGroupBy);
        }
      }

      fieldGb.entityKind = entityKind;
      let binSize = fieldGb.bin?.BinSize;
      if (
        binSize === "" &&
        filterIdRef.current &&
        (fieldGb.dataType?.toLowerCase() === "integer" ||
          fieldGb.dataType?.toLowerCase() === "number")
      ) {
        axios
          .get(
            `${dataRoot}/api/v1/data/bin-size/${filterIdRef.current}/${btoa(
              encodeURIComponent(fieldGb.property)
            )}?normalizeField=${
              useNormalizeByRef.current ? globalNormalizeByRef.current.field : "''"
            }&per=${
              useNormalizeByRef.current ? globalNormalizeByRef.current.per : 0
            }&pdenSource=${toPdenEnum(
              fieldGb.pdenSource
            )}&entityKind=${entityKind}&useDynamicBins=${fieldGb.bin?.UseDynamicBins}`
          )
          .then((response) => {
            if (response.status === 200) {
              const bin = response.data;
              binSize = bin.binSize;
              fieldGb.bin.BinSize = binSize ?? "";
              fieldGb.bin.LessThan = bin.lessThan ?? "";
              fieldGb.bin.GreaterThan = bin.greaterThan ?? "";
              // when dynamically updating bins, keep the same bin type
              fieldGb.bin.BinType = updateBinsOnly
                ? fieldGb.bin.BinType
                : defaults?.binSettings?.type;
              fieldGb.bin.Quantile =
                defaults?.binSettings?.quantile ?? fieldGb.bin.Quantile;
              fieldGb.bin.IsLocked = bin.isLocked ?? false;
              dispatch(setGlobalGroupBy(fieldGb));
              setBin(fieldGb.bin);
              toggleGroupByList();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            toast.error(`Failed to get bin size for ${fieldGb.title}`);
          });
      } else {
        dispatch(setGlobalGroupBy(fieldGb));
        setBin(fieldGb.bin);
        toggleGroupByList();
      }
    },
    [dispatch, filterId, showGroupBy, defaults]
  );

  function performBatchOption(action) {
    if (action === "checkAll") {
      addSelectedLegendItemsToFilter(filteredItems, true);
    } else if (action === "uncheckAll") {
      removeSelectedLegendItemsFromFilter(filteredItems);
    } else if (action === "invertChecks") {
      invertSelectedLegendItemsFromFilter(filteredItems);
    }
  }

  const addCheckedItemsToFilter = useCallback(() => {
    const legendFilters = checkedLegendItems.current.map((item) => {
      const pdenSource =
        typeof groupBy.pdenSource === "string"
          ? PdenSourceEnum[groupBy.pdenSource]
          : parseInt(groupBy.pdenSource);
      return getLegendFilter(
        item.property,
        item.canBin,
        [item].map((item) => item.title),
        item.categoryId,
        item.bin,
        item.normalizeBySettings,
        item.isNotEqual,
        pdenSource,
        item.value,
        item.groupByTitle,
        item.partialContextTitle,
        item.isForecastToggleOn,
        item.selectedForecastFolderName
      );
    });
    const hasQuantileBinFilter =
      checkedLegendItems.current.filter((item) => item.bin.BinType == "Quantile").length >
      0;

    //Lock the map if filtering by quantile because we do not want the distribution to change

    dispatch(setViewLock(hasQuantileBinFilter || mapViewLocked));
    if (entityKind === EntityKind.Well) {
      dispatch(
        addManyPropertiesToFilter({
          legendFilters,
          propertyName: groupBy.property
        })
      );
    } else {
      dispatch(
        addManyFacilityPropertiesToFilter({
          legendFilters,
          propertyName: groupBy.property
        })
      );
    }
  }, [dispatch, mapViewLocked, groupBy.pdenSource, groupBy.property]);

  useEffect(() => {
    dispatch(getNormalizationItems());
  }, []);

  useEffect(() => {
    if (wellListFilter?.length !== 0) {
      // clear the checked legend items when a well list is pasted
      checkedLegendItems.current = [];
    }
  }, [wellListFilter]);

  useEffect(() => {
    const subscriber = subject.subscribe(() => {
      addCheckedItemsToFilter();
    });
    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, [addCheckedItemsToFilter, checkedLegendItems]);

  const addSelectedLegendItemsToFilter = useCallback(
    (items, equal) => {
      const selected: LegendItemModel[] = [...items.filter((item) => !!item)];
      for (const item of selected) {
        if (!item) {
          continue;
        }
        item.isChecked = true;
        item.isNotEqual = !equal;
        item.normalizeBySettings = Object.assign({}, globalNormalizeByRef.current, {
          useNormalizeBy: useNormalizeByRef.current
        });
      }
      addManyItemsToChecked(selected);
      if (!groupBy.bin?.IsLocked) {
        toggleLockBins(true);
      }
    },
    [groupBy.bin]
  );

  const checkOnlySelected = useCallback(
    (items, equal) => {
      const selected: LegendItemModel[] = [...items.filter((item) => !!item)];
      removeSelectedLegendItemsFromFilter(checkedLegendItems.current);
      for (const item of selected) {
        if (!item) {
          continue;
        }
        item.isChecked = true;
        item.isNotEqual = !equal;
        item.normalizeBySettings = Object.assign({}, globalNormalizeByRef.current, {
          useNormalizeBy: useNormalizeByRef.current
        });
      }
      addManyItemsToChecked(selected);
      if (!groupBy.bin?.IsLocked && !groupBy.bin?.BinSize) {
        toggleLockBins(true);
      }
    },
    [groupBy.bin]
  );

  const removeSelectedLegendItemsFromFilter = useCallback((items) => {
    const selected = [...items];
    for (const item of selected) {
      item.isChecked = false;
      item.isNotEqual = false;
    }
    addManyItemsToChecked(selected);
  }, []);

  const invertSelectedLegendItemsFromFilter = useCallback((items) => {
    const selected = [...items];
    for (const item of selected) {
      if (item.isNotEqual) continue;
      item.isChecked = !item.isChecked;
    }
    addManyItemsToChecked(selected);
  }, []);

  const hasCheckedItems = useMemo(
    () =>
      legendItems?.length &&
      legendItems.some((item) => item.isChecked && !item.isNotEqual),
    [legendItems]
  );

  const hasEmptyBin = useMemo(
    () => legendItems?.length && legendItems.some((item) => item.count === 0),
    [legendItems]
  );

  //If the button to only view selected bins is disabled reset it to it's default (true)
  if (!hasCheckedItems && !showGroupsNotInFilter && !hasEmptyBin) {
    dispatchUpdateShowGroupsNotInFilter(true);
  }

  const contextMenuItems = useMemo(() => {
    return [
      {
        name: "Check",
        icon: <StyledCheckBoxIcon />,
        onClick: () => addSelectedLegendItemsToFilter(selectedLegendItems, true)
      },
      {
        name: "Check Only Selected",
        icon: <StyledCheckBoxIcon />,
        onClick: () => checkOnlySelected(selectedLegendItems, true)
      },
      {
        name: "Uncheck",
        icon: <StyledCheckBoxEmptyIcon />,
        onClick: () => removeSelectedLegendItemsFromFilter(selectedLegendItems)
      },
      {
        name: "Exclude",
        icon: <StyledExcludeIcon />,
        onClick: () => addSelectedLegendItemsToFilter(selectedLegendItems, false)
      }
    ];
  }, [selectedLegendItems]);

  const ContextMenuItemsComponent = ({ onSelect }) => {
    const handleClick = (value) => () => {
      onSelect(value);
      value.onClick();
    };

    return (
      <>
        {contextMenuItems.map((item) => (
          <LegendContextMenuItem key={item.name} onClick={handleClick(item)}>
            {item.icon} {item.name}
          </LegendContextMenuItem>
        ))}
      </>
    );
  };

  //callback here to fetch the latest filterId
  const handleGroupByChange =
    () =>
    (
      groupBy,
      isForecastToggleOn,
      selectedForecastFolder,
      selectedForecastFolderName,
      selectedPdenSource
    ) => {
      setCumPlusForecast(isForecastToggleOn);
      setIsForecastToggleOn(isForecastToggleOn);
      setSelectedForecastFolder(selectedForecastFolderName);
      const labelContext = getColumnLabelWithContext(
        groupBy,
        useNormalizeBy,
        normalizeBy,
        isForecastToggleOn,
        selectedForecastFolderName,
        entityKind
      );
      updateGroupBy(
        groupBy.property,
        columnsDictionaryRef.current,
        updateField,
        Object.prototype.hasOwnProperty.call(localBinsRef.current, groupBy.property)
          ? localBinsRef.current[groupBy.property]
          : null,
        selectedForecastFolderName,
        labelContext.fullContextTitleWithNormalization,
        labelContext.partialContextTitle,
        isForecastToggleOn,
        selectedPdenSource,
        entityKind,
        defaults.binSettings
      );
    };

  function updateGroupByBin(
    binType: BinType,
    quantile: QuantileState,
    useDynamicBins: boolean
  ) {
    let newBinType = binType;
    // Show type as dynamic but function as bin size besides useDynamicBins
    if (binType === "Dynamic") {
      newBinType = "BinSize";
    }
    const newBin = {
      BinSize: bin.BinSize,
      MinSize: bin.MinSize,
      GreaterThan: bin.GreaterThan,
      LessThan: bin.LessThan,
      Quantile: quantile,
      BinType: newBinType,
      UseDynamicBins: useDynamicBins
    };
    const newGroupBy = Object.assign({}, groupBy, {
      bin: newBin
    });
    dispatch(setGlobalGroupBy(newGroupBy));
  }

  function resetGroupByBin() {
    updateGroupBy(
      groupBy.property,
      columnsDictionary,
      updateField,
      null,
      groupBy.forecastFolder,
      groupBy.fullContextTitleWithNormalization,
      groupBy.partialContextTitle,
      groupBy.isForecastToggleOn,
      "Public",
      entityKind,
      defaults.binSettings
    );
    const newLocals = {};
    Object.keys(localBinsRef.current).forEach((key) => {
      if (key !== groupBy.property) newLocals[key] = { ...localBinsRef.current[key] };
    });
    localBinsRef.current = newLocals;
  }

  async function toggleLockBins(isLocked?: boolean) {
    if (bin.IsLocked) {
      performBatchOption("uncheckAll");
    }
    const newBin = Object.assign({}, bin?.BinSize !== "" ? bin : groupBy.bin, {
      IsLocked: isLocked !== undefined ? isLocked : !bin.IsLocked
    });
    const newGroupBy = Object.assign({}, groupBy, {
      bin: newBin
    });
    dispatch(setGlobalGroupBy(newGroupBy));
    updateBin({ IsLocked: newBin.IsLocked });
  }

  function onSelectedFilterChanged(selectedFilter: SavedFilterModel) {
    if (entityKind === EntityKind.Well) {
      dispatch(setSelectedSavedFilter(selectedFilter));
    } else {
      dispatch(setSelectedFacilitySavedFilter(selectedFilter));
    }
    if (!selectedFilter || !selectedFilter.filterJSON) {
      return;
    }
    const filter = JSON.parse(selectedFilter.filterJSON);
    if (filter.polygonFilter) {
      dispatch(setViewLock(true));
    } else {
      dispatch(setViewLock(false));
    }
    if (entityKind === EntityKind.Well) {
      dispatch(setFilterState(filter));
    } else {
      dispatch(setFacilityFilterState(filter));
    }
    checkedLegendItems.current = filter.checkedItems;
  }

  const updateSelectedLegendItems = useCallback(
    (items: LegendItemModel[]) => {
      dispatch(
        entityKind === EntityKind.Well
          ? setSelectedGroups(items.map((s) => s?.title).filter((f) => f && f !== ""))
          : setSelectedFacilityGroups(
              items.map((s) => s?.title).filter((f) => f && f !== "")
            )
      );
      setSelectedLegendItems(items);
      outerSelectedLegendItems = items;
    },

    [selectedLegendItems]
  );

  const addManyItemsToChecked = useCallback(
    (items) => {
      for (const item of items) {
        //remove from item
        const index = checkedLegendItems.current.findIndex(
          (x) => x.property === item.property && x.value === item.value
        );
        if (index >= 0) {
          checkedLegendItems.current.splice(index, 1);
        }
        if (item.isChecked) {
          checkedLegendItems.current.push(item);
        }
      }
      //notify subject that values changed
      subject.next(checkedLegendItems.current.length);
    },
    [checkedLegendItems]
  );

  const addItemToChecked = useCallback(
    (item) => {
      //remove from item
      if (!checkedLegendItems.current || !item) {
        return;
      }
      const index = checkedLegendItems.current.findIndex(
        (x) => x.property === item.property && x.value === item.value
      );
      if (index >= 0) {
        checkedLegendItems.current.splice(index, 1);
      }
      if (item.isChecked) {
        checkedLegendItems.current.push(item);
      }
      if (!item.isChecked && item.value === updatePlayLegend) {
        dispatch(setUpdatePlayLegend(""));
      }
      //notify subject that values changed
      subject.next(checkedLegendItems.current.length);
    },

    [checkedLegendItems, updatePlayLegend]
  );

  const replacePlayChecked = useCallback(
    (item) => {
      checkedLegendItems.current = checkedLegendItems.current.filter(
        (x) => x.property !== "Header.ResourcePlay"
      );
      const index = checkedLegendItems.current.findIndex(
        (x) => x.property === item.property && x.value === item.value
      );
      if (index >= 0) {
        checkedLegendItems.current.splice(index, 1);
      }
      if (item.isChecked) {
        checkedLegendItems.current.push(item);
      }
      subject.next(checkedLegendItems.current.length);
    },
    [checkedLegendItems]
  );

  const colorLockItem = useCallback((item) => {
    item.isColorLocked = !item.isColorLocked;
    if (item.isColorLocked) {
      dispatch(
        addToLockedColor({
          color: item.color,
          value: item.value,
          property: item.property
        })
      );
    } else {
      dispatch(
        removeLockedColor({
          color: item.color,
          value: item.value,
          property: item.property
        })
      );
    }
  }, []);

  const dispatchResetPolygonFilter = (reset: boolean) => {
    if (entityKind === EntityKind.Well) {
      dispatch(setResetPolygonFilter(reset));
    } else {
      dispatch(setFacilityResetPolygonFilter(reset));
    }
  };

  const onActiveFilterNodeRemoved = useCallback((node) => {
    const prop = node.property;
    const values = node.value;
    if (
      node.type === "Polygon" ||
      (node.predicates && node.predicates[0].type === "Polygon")
    ) {
      if (
        node.source === "selection" ||
        (node.predicates && node.predicates[0].source === "selection")
      ) {
        dispatchResetPolygonFilter(true);
        return;
      }
      clearAllPropertiesFilter();
      return;
    }
    if ((!values || values.length === 0) && values !== "") {
      return;
    }
    let value = values;
    if (Array.isArray(value)) {
      if (value.length > 1) {
        value = `${value[0]} - ${value[1]}`;
      } else if (value.length === 1) {
        value = value[0];
      }
    }
    value = value.toString();

    if (value === "0" && (node.operator === ">" || node.operator === "<")) {
      value = `${node.operator} 0`;
    } else if (value === "") {
      value = `n/a`;
    }

    checkedLegendItems.current = checkedLegendItems.current?.filter((li) => {
      return li.property !== prop || li.value !== value;
    });
  }, []);

  const onActiveFilterDoubleClick = useCallback(
    (node) => {
      if (!node.actualValue.property) return;
      const field = node.actualValue.property;
      const bin = node.actualValue.bin;
      if (node.actualValue.isForecastToggleOn) {
        setIsForecastToggleOn(node.actualValue.isForecastToggleOn);
      }
      if (node.actualValue.selectedForecastFolderName) {
        setSelectedForecastFolder(node.actualValue.selectedForecastFolderName);
      }
      updateGroupBy(field, columnsDictionaryRef.current, updateField, bin, entityKind);
    },
    [updateField]
  );

  function getActiveFilterItem(): FilterItemT[] {
    return [ActiveFilterItem];
  }

  async function updateSavedFilters() {
    try {
      const data = await loadSavedFilters(currentProjectIdRef.current, entityKind);
      //data changed
      if (!data || data.savedFilters.length === 0) {
        setSavedEntityFilters(getActiveFilterItem());
        return;
      }
      const filters = getActiveFilterItem().concat(data.savedFilters);
      setSavedEntityFilters(filters);
      setEntityFiltersLoaded(true);
      const hasOnlySystemFilters =
        entityKind == EntityKind.Well
          ? data.savedFilters.length === NUMBER_OF_WELL_SYSTEM_FILTERS
          : data.savedFilters.reduce((acc, filter) => {
              return acc && filter.name == "Facility Default";
            }, true);
      const filterProjectId =
        entityKind == EntityKind.Well &&
        data.savedFilters.length > NUMBER_OF_WELL_SYSTEM_FILTERS
          ? data.savedFilters[NUMBER_OF_WELL_SYSTEM_FILTERS].projectID
          : data.savedFilters.length > 1
          ? data.savedFilters[1].projectID
          : null;
      if (
        // user has no default project
        !defaults?.project ||
        hasOnlySystemFilters ||
        selectedProjectId === filterProjectId // saved filters are part of current project
      ) {
        // Mark the component ready to load the default filter now that the saved
        // filters for the default project have loaded.
        setReadyForDefaultFilter(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    if (!readyForDefaultFilter || defaultFiltersLoaded) {
      return;
    }
    const defaultFilter =
      entityKind == EntityKind.Well ? defaults?.filter : defaults?.facilityFilter;
    if (defaultFilter) {
      // user has a default filter
      let match = savedFilters.find(
        (x) => x.id === defaultFilter.filterId && !!defaultFilter.filterId
      );
      if (!match && savedFilters.length > NUMBER_OF_WELL_SYSTEM_FILTERS) {
        //default filter has been deleted, set system default
        match = savedFilters[1];
      } else if (!match && savedFilters.length > 0) {
        // no system default filters, use the first filter
        match = savedFilters[0];
      }
      if (entityKind == EntityKind.Well) {
        dispatch(setSelectedSavedFilter(match));
      } else {
        dispatch(setSelectedFacilitySavedFilter(match));
      }
      onSelectedFilterChanged(match);
    } else if (
      savedFilters.length > NUMBER_OF_WELL_SYSTEM_FILTERS &&
      entityKind == EntityKind.Well
    ) {
      dispatch(setSelectedSavedFilter(savedFilters[1]));
      onSelectedFilterChanged(savedFilters[1]);
    } else if (savedFilters.length == 2 && entityKind == EntityKind.Facility) {
      dispatch(setSelectedFacilitySavedFilter(savedFilters[1]));
      onSelectedFilterChanged(savedFilters[1]);
    }
    setDefaultFiltersLoaded(true);
  }, [readyForDefaultFilter, defaultFiltersLoaded, entityKind]);

  useEffect(() => {
    currentProjectIdRef.current = selectedProjectId;
    if (selectedProjectId) {
      updateSavedFilters();
    }
  }, [selectedProjectId, selectedProjectTransaction, entityKind]);

  const handleFocusSearchKeydown = () => (e) => {
    if (e.key === "Escape") {
      setFocusSearch("");
    }
  };

  const handleLegendCheckChange = () => (val, item) => {
    item.isChecked = val;
    item.isNotEqual = false;
    item.normalizeBySettings = Object.assign({}, globalNormalizeByRef.current, {
      useNormalizeBy: useNormalizeByRef.current
    });
    addItemToChecked(item);
    if (savedFilters.length > 0) {
      onSelectedFilterChanged(savedFilters[0]);
    }
    if (!groupBy.bin?.IsLocked) {
      toggleLockBins(true);
    }
  };

  // classnames
  const wrapperClassNames = classnames({
    "with-legend-off": !isLegendVisible,
    "with-filter-off": !isActiveFilterVisible
  });

  const activePalette = useSelector((state: RootState) =>
    entityKind === EntityKind.Facility
      ? state.userSetting.activeFacilityColorPalette
      : state.userSetting.activeColorPalette
  );

  const handlePaletteSelection = (p) => {
    if (entityKind === EntityKind.Facility) {
      dispatch(setActiveFacilityColorPalette(p));
      return;
    }
    dispatch(setActiveColorPalette(p));
    refreshPreferredColorsInStore(p);
  };

  if (!selectedProjectId) {
    return <div>Loading projects...</div>;
  }

  function handleCollapseChange(keys) {
    const isFilterOpen = keys.includes("filter");
    const isLegendOpen = keys.includes("legend");

    setIsActiveFilterVisible(isFilterOpen);
    setIsLegendVisible(isLegendOpen);
  }

  function handlePresetSelection(property: string) {
    updateGroupBy(
      property,
      columnsDictionaryRef.current,
      updateField,
      Object.prototype.hasOwnProperty.call(localBinsRef.current, property)
        ? localBinsRef.current[property]
        : null,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      entityKind,
      defaults.binSettings
    );
    setFocusSearch("");
  }

  const handleRecentFocusItemClick: MenuProps["onClick"] = ({ key }) => {
    if (Number(key) < 0) return;
    handlePresetSelection(key as string);
  };

  return (
    <Wrapper className={`${wrapperClassNames} focus-activity ${entityKind}`}>
      <Collapse
        defaultActive={["focus", "legend", "filter"]}
        onChange={handleCollapseChange}>
        <Panel
          name="production"
          extra={<ProductionButtonGroup entityKind={entityKind} />}
          title={
            <ProductionHeader>
              Production{" "}
              {entityKind == EntityKind.Well ? (
                <GroupByIcon />
              ) : (
                <Icon path={mdiFactory} size={1.0} />
              )}
            </ProductionHeader>
          }
        />

        <Panel
          name="focus"
          data-testid="focus-selector-panel"
          extra={
            <PresetDropdownContext.Provider value={true}>
              <PresetDropdown
                entityKind={entityKind}
                onGroupByChange={handleGroupByChange()}
                onRecentFocusItemClick={handleRecentFocusItemClick}
              />
            </PresetDropdownContext.Provider>
          }
          title="Focus"
          toggleTooltipText="Toggle Focus Favourites">
          <PresetFields onSelect={handlePresetSelection} entityKind={entityKind} />
        </Panel>

        <Panel
          name="bin"
          data-testid="bin-settings-panel"
          title="Bin Settings"
          extra={
            warningVisible && (
              <Warning
                style={{
                  fontSize: "18px",
                  color: "#FFA500",
                  position: "absolute",
                  left: "125px"
                }}
              />
            )
          }
          toggleTooltipText="Toggle Bin Settings">
          <BinSettings
            bin={bin}
            canEditBin={canEditBin}
            onBinChange={updateBin}
            onBinSettingChange={updateGroupByBin}
            onLock={toggleLockBins}
            onReset={resetGroupByBin}
            isWarningVisible={warningVisible}
            groupByPropertyName={groupBy.property}
          />
        </Panel>

        <Panel
          name="legend"
          data-testid="focus-filter-panel"
          loading={isFiltering}
          title={
            <StyledText strong ellipsis>
              Focus:&nbsp;
              <Tooltip title={legendTitle}>
                <span className="no-case">{legendTitle}</span>
              </Tooltip>
            </StyledText>
          }
          toggleTooltipText="Toggle Focus Bins">
          <LegendContent>
            <LegendContentHeader>
              <Checkbox
                checked={isAllChecked}
                onChange={() =>
                  performBatchOption(hasCheckedItems ? "uncheckAll" : "checkAll")
                }
                indeterminate={hasCheckedItems && !isAllChecked}
              />

              <BatchOperationsToggle onSelect={(v) => performBatchOption(v)} />

              <Input
                value={focusSearch}
                onChange={(e) => setFocusSearch(e.target.value)}
                placeholder="Search"
                onKeyDown={handleFocusSearchKeydown()} //clear on escape
              />

              {/*showGroupsNotInFilter has the negation since button is at its default state when it is true and thus should be grey*/}
              <div>
                <ToggleButton
                  tooltipText={`Only view selected bins`}
                  value={!showGroupsNotInFilter}
                  onClick={dispatchToggleShowGroupsNotInFilter}
                  disabled={!hasCheckedItems && !hasEmptyBin}>
                  {showGroupsNotInFilter ? (
                    <VisibilityIcon fontSize="large" />
                  ) : (
                    <VisibilityOffIcon fontSize="large" />
                  )}
                </ToggleButton>
              </div>

              <ToggleButton
                tooltipText={`Switch to ${showTreeMap ? "List" : "tree map"}`}
                value={showTreeMap}
                onClick={() => setShowTreeMap((prev) => !prev)}>
                {showTreeMap && <ListIcon fontSize="large" />}
                {showTreeMap || <ChartTreeIcon />}
              </ToggleButton>

              <ColorPaletteListToggle
                activePalette={activePalette}
                onPaletteSelected={handlePaletteSelection}
              />

              <SortOptionsToggle />
            </LegendContentHeader>

            {!showTreeMap && (
              <StyledLegend
                allowChecked
                title={legendTitle}
                items={filteredItems}
                selectedItems={selectedLegendItems}
                contextMenuContent={ContextMenuItemsComponent}
                onChange={updateSelectedLegendItems}
                onClickLock={colorLockItem}
                onCheckChanged={handleLegendCheckChange()}
                entityKind={entityKind}
              />
            )}

            {showTreeMap && (
              <FocusTreeMap
                items={filteredItems}
                onChange={(items) => addSelectedLegendItemsToFilter(items, true)}
              />
            )}
          </LegendContent>
        </Panel>

        <Panel
          name="filter"
          data-testid="active-filter-panel"
          toggleTooltipText="Toggle Active Filter"
          loading={isFiltering}
          extra={
            <ActiveFilterHeader>
              <ErrorBoundary>
                <SavedFilterSelectionWrapper
                  data-testid={`saved-filter-selection-${entityKind}`}
                  className={`saved-filter-selection-${entityKind}`}
                  entityKind={entityKind}
                  savedFilters={savedFilters}
                  selectedFilter={selectedFilter}
                  onSelectedFilterChanged={onSelectedFilterChanged}
                />
              </ErrorBoundary>

              <UndoFilter entityKind={entityKind} />

              {projectPermissions?.canEditFilters && !!selectedProject && (
                <SaveActiveFilter
                  entityKind={entityKind}
                  project={selectedProject}
                  checkedLegendItems={checkedLegendItems}
                  filterName={selectedFilter?.name}
                  onSaveFilter={async () => {
                    await updateSavedFilters();
                    dispatch(updateSelectedProjectTransaction(""));
                  }}
                />
              )}

              <ClearAllLink onClick={clearAllPropertiesFilter}>
                <RemoveCircleOutlineIcon />
                Clear all
              </ClearAllLink>
            </ActiveFilterHeader>
          }>
          <StyledActiveFilter
            entityKind={entityKind}
            onNodeRemoved={onActiveFilterNodeRemoved}
            onMouseDoubleClick={onActiveFilterDoubleClick}
          />
        </Panel>
      </Collapse>
    </Wrapper>
  );
};

export default GroupByFilterActivity;

/************ HELPER FUNCTIONS **********/

async function loadSavedFilters(projectId, entityKind: EntityKind) {
  const response = await axios.post(process.env.REACT_APP_SAVED_FILTER_GQL_SERVICE, {
    operationName: "savedFilters",
    variables: { req: { projectID: projectId, entityKind } },
    query:
      "query savedFilters($req: SavedFilterInput!) {\n  savedFilters(req: $req) {\n    id\n    name\n    projectID\n    filterJSON\n    owner\n    __typename\n  }\n}\n"
  });
  if (response.status === 200) {
    return response.data.data;
  }
  return null;
}

const subject = new Subject<number>();

/************ end helper functions **********/

/************ styled components **********/

const Wrapper = styled.div`
  height: calc(100vh - 45px);
  background: #ffffff;
  /* override white background for sections */
  --normalize-section-bg: #f4f6f6;
  --bin-section-bg: #f4f6f6;
  --active-filter-section-bg: #fcfcfc;
  display: grid;
  /* make Legend section take available space and
  ActiveFilter 300px max. when active */
  grid-template-rows: repeat(3, auto) 1fr minmax(auto, 270px);
  align-content: start;

  .ant-spin {
    min-width: 40px;
  }

  & > div {
    overflow-y: hidden;
  }

  /* expand Legend to take available space when ActiveFilter is inactive, or
  or both are inactive */

  &.with-filter-off,
  &.with-filter-off.with-legend-off {
    grid-template-rows: repeat(3, auto) 1fr 40px;
  }

  /* expand ActiveFilter to take available space when Legend is inactive */

  &.with-legend-off {
    grid-template-rows: repeat(3, auto) auto 1fr;
  }
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
`;

const LegendContent = styled.div`
  flex: 1;
  max-height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
  overflow-y: hidden;
`;
const LegendContentHeader = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: 24px 36px 1fr repeat(4, 28px);
  padding: 0 15px;
  place-items: center;

  .BaseInput {
    height: 28px;
  }
`;
const StyledLegend = styled(Legend)`
  flex-grow: 1;
  max-width: 100%;
  min-width: 100%;
  min-height: 0;
`;
const LegendContextMenuItem = styled.div`
  min-width: 180px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text-60);
  font-weight: var(--fontWeightMedium);
  padding: 6px 0;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
  }
`;
const ActiveFilterHeader = styled.div`
  flex-grow: 1;
  min-width: 0;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 1px;

  .trigger-container:nth-child(1) {
    flex-grow: 1;
    min-width: 0;
  }
`;

const SavedFilterSelectionWrapper = styled(SavedFilterSelection)`
  text-overflow: ellipsis;
  max-width: 100%;
`;

const StyledActiveFilter = styled(ActiveFilter)`
  background: var(--active-filter-section-bg);
  flex-grow: 1;
  padding-left: 15px;
`;

const ClearAllLink = styled.a`
  min-width: 66px;
  font-size: 1.2rem;
  color: #b5b5b5;
  transition: color 150ms;
  cursor: pointer;

  .MuiSvgIcon-root {
    vertical-align: sub;
    margin-right: 2px;
  }
`;

const ProductionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin-right: 12px;
  white-space: nowrap;
  font-weight: 700;

  svg {
    color: gray;
    width: 15px;
    height: 15px;
  }
`;
