import { useQuery } from "react-query";

import axios, { AxiosError } from "axios";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream/network-fractions`;

export const GetNetworkFractionKey = "network-fraction";

export interface NetworkFraction {
  wellId: string;
  gasPct: number;
  wellName: string;
  nodeDistance: number;
  gasMcf: number;
}

const fetchNetworkFraction = async (facilityId?: string) => {
  if (!facilityId) {
    return Promise.resolve([]);
  }
  const response = await axios.get<NetworkFraction[]>(`${API_URL}/${facilityId}`);
  const data = response?.data;
  if (!Array.isArray(data)) {
    return [];
  }
  return response?.data ?? [];
};

const useGetFacilityNetworkFraction = (facilityId: string) => {
  return useQuery<NetworkFraction[], AxiosError>(
    [GetNetworkFractionKey, facilityId],
    () => fetchNetworkFraction(facilityId),
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useGetFacilityNetworkFraction;
